/* eslint-disable no-undef */
const pathname = window.location.pathname.substring(0, 3)
if (pathname === '/en') {
  i18n.locale = 'en'
} else {
  i18n.locale = 'th'
}

import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import ApiClient from './services'
import BootstrapVue from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import vUploader from 'v-uploader'
import Notifications from 'vue-notification'
import App from './App.vue'
import './assets/scss/style.scss'
import SocialSharing from 'vue-social-sharing'
import VueGtag from 'vue-gtag'
import { i18n } from './i18n'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { Skeleton } from 'vue-loading-skeleton'
import * as Sentry from "@sentry/vue";

import momentTZ from 'moment-timezone'
momentTZ.tz.setDefault('Asia/Bangkok')
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import moment from 'moment'
const locale = window.localStorage.getItem('locale') || 'th'
moment.locale(locale)

import { FACEBOOK_APP_ID_PRODUCTION, FACEBOOK_APP_ID_TEST } from './config/constants'
import { isProduction, isAuthenticated } from './utils'

Vue.use(BootstrapVue)
Vue.use(PortalVue)
Vue.use(VCalendar)
Vue.use(vUploader)
Vue.use(VueScrollTo)
Vue.use(VueMeta)
Vue.use(BootstrapVueIcons)
Vue.use(SocialSharing)
Vue.use(Skeleton)
Vue.use(Notifications)
Vue.use(Skeleton)
Vue.use(VueGtag, {
  config: { id: 'UA-113910924-4' },
})
Vue.prototype.$momentTZ = momentTZ

const authStore = createNamespacedHelpers('auth')
const userStore = createNamespacedHelpers('user')

Sentry.init({
  Vue,
  dsn: "https://0113352789ed8a98b92ac073195e13c1@o4507259523170304.ingest.us.sentry.io/4507303557922816",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // /^https:\/\/bluuu\.co/,/^https:\/\/dev.bluuu.co/, /^https:\/\/uat.bluuu.co/ ,"localhost"
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  async beforeMount() {
    // window.fbAsyncInit = async () => {
    //   window.FB.init({
    //     appId: isProduction() ? FACEBOOK_APP_ID_PRODUCTION : FACEBOOK_APP_ID_TEST,
    //     xfbml: true,
    //     version: 'v4.0'
    //   })
    //   window.FB.AppEvents.logPageView()
    // }
    //   ; ((d, s, id) => {
    //     var js,
    //       fjs = d.getElementsByTagName(s)[0]
    //     if (d.getElementById(id)) {
    //       return
    //     }
    //     js = d.createElement(s)
    //     js.id = id
    //     js.src = 'https://connect.facebook.net/en_US/sdk.js'
    //     fjs.parentNode.insertBefore(js, fjs)
    //   })(document, 'script', 'facebook-jssdk')
  },
  async mounted() {
    // console.log("hello");
    // await this.refreshToken()
    if (isAuthenticated()) {
      // console.log("getUserProfile - main.js");
      await this.getUserProfile()
    } else {
      // console.log("refreshToken - main.js");
      await this.refreshToken()
    }
  },
  methods: {
    async checkLoginState() {
      // fix me - recheck getLoginStatus if possible
      console.log('checkLoginState')
      if (!isAuthenticated()) {
        window.FB.getLoginStatus(this.statusChangeCallback)
      } else {
        console.log('refresh token')
        await this.refreshToken()
      }
    },
    async statusChangeCallback(response) {
      console.log('statusChangeCallback', response)
      this.updateAuthState(response)
      const { authResponse } = response
      if (authResponse) {
        try {
          await ApiClient.userRegister({
            fb_access_token: authResponse.accessToken,
          })
        } catch (error) {
          console.log('error register', error.response)
          await this.getOAuthToken({
            username: authResponse.userID,
            password: authResponse.accesToken,
          })
        }
      }
    },
    ...authStore.mapActions([
      'updateAuthState',
      'updateSessionState',
      'refreshToken',
      'getOAuthToken',
    ]),
    ...userStore.mapActions(['initUserProfileFromLocalStorage', 'getUserProfile']),
  },
  watch: {
    '$i18n.locale': function (newVal) {
      window.localStorage.setItem('locale', newVal)
      moment.locale(newVal)
    },
  },
  router,
  BootstrapVue,
  VCalendar,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
